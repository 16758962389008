/**
 * = Accordions
 */

.accordion-button {
  text-align: left;

  .btn-check:focus + .btn,
  .btn:focus {
    outline: 0;
    box-shadow: none;
  }

  &:hover {
    background-color: $gray-200;
  }
}

// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-button {
    background-color: transparent;
    &:not(.collapsed) {
      color: $secondary;
    }
    &:focus {
      border-color: $gray-300;
      box-shadow: none;
    }
  }
  .accordion-collapse {
    border: 0;
  }
}

.accordion-item:last-of-type .accordion-button.collapsed,
.accordion-collapse.collapse.show {
  color: #eaedf2;
  background-color: #262b40;
  border: 0;
}

.accordion-item:last-of-type .accordion-button.collapsed:hover {
  background-color: #2e3650;
}

.accordion-item {
  border: 0;
  background-color: #262b40;
}
